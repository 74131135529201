/* eslint-disable @typescript-eslint/camelcase */
import { toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import {
  ClientRegistrationRequest,
  ClientRegistrationApproveRejectRequest,
  CreateClientBranchRequest,
  EditRenewRatesRequest
} from "../contracts/ClientRegistrationRequest";

export class ClientRegistrationApiRequest implements ClientRegistrationRequest {
  private readonly company_name: string;
  private readonly email: string;
  private readonly phone: string;
  private readonly address: string;
  private readonly district_code: string;
  private readonly tax_number: string;
  private readonly version_basic: string;
  private readonly is_custom_rate: boolean;
  private readonly is_custom_rate_only: boolean;
  private readonly discount: number;
  private readonly price_rate_start_date: string;
  private readonly price_rate_end_date: string;
  private readonly version_name: string;
  private readonly archive_file: any;
  private readonly category: string;
  private readonly bank_name: string;
  private readonly bank_account_name: string;
  private readonly bank_account_number: string;
  private readonly bank_account_email: string;
  private readonly bank_account_label: string;
  private readonly contact_name: string;
  private readonly contact_job_title: string;
  private readonly contact_email: string;
  private readonly contact_phone: string;
  private readonly type: string;
  private readonly company_size: string;
  private readonly is_efaktur_with_nik: string;
  private readonly nik_id: string;
  private readonly nik_name: string;
  private readonly nik_address: string;
  private readonly taxpayer_name: string;
  private readonly taxpayer_address: string;
  private readonly billing_wa_number: string;
  private readonly billing_email: string;
  private readonly billing_schedule_so: string;
  private readonly billing_payment_periode: string;
  private readonly va_bank_name: string;
  private readonly va_bank_number: string;
  private readonly va_bank_account_name: string;
  private readonly va_bank_label: string;
  private readonly va_bank_email: string;
  private readonly contract_start_date: string;
  private readonly contract_end_date: string;
  private readonly contract_attachment: any;
  private readonly client_type: string;
  private readonly payment_method: string;

  constructor(
    company_name: string,
    email: string,
    phone: string,
    address: string,
    district: string,
    tax_number: string,
    version_basic: string,
    is_custom_rate: boolean,
    is_custom_rate_only: boolean,
    discount: number,
    price_rate_start_date: string,
    price_rate_end_date: string,
    version_name: string,
    archive_file: any,
    category: string,
    bank_name: string,
    bank_account_name: string,
    bank_account_number: string,
    bank_account_email: string,
    bank_account_label: string,
    contact_name: string,
    contact_job_title: string,
    contact_email: string,
    contact_phone: string,
    type: string,
    company_size: string,
    is_efaktur_with_nik: string,
    nik_id: string,
    nik_name: string,
    nik_address: string,
    taxpayer_name: string,
    taxpayer_address: string,
    billing_wa_number: string,
    billing_email: string,
    billing_schedule_so: string,
    billing_payment_periode: string,
    va_bank_name: string,
    va_bank_number: string,
    va_bank_account_name: string,
    va_bank_label: string,
    va_bank_email: string,
    contract_start_date: string,
    contract_end_date: string,
    contract_attachment: any,
    client_type: string,
    payment_method: string
  ) {
    this.company_name = company_name;
    this.email = email;
    this.phone = phone;
    this.address = address;
    this.district_code = district;
    this.tax_number = tax_number;
    this.version_basic = version_basic;
    this.is_custom_rate = is_custom_rate;
    this.is_custom_rate_only = is_custom_rate_only;
    this.discount = discount;
    this.price_rate_start_date = price_rate_start_date;
    this.price_rate_end_date = price_rate_end_date;
    this.version_name = version_name;
    this.archive_file = archive_file;
    this.category = category;
    this.bank_name = bank_name;
    this.bank_account_name = bank_account_name;
    this.bank_account_number = bank_account_number;
    this.bank_account_email = bank_account_email;
    this.contact_name = contact_name;
    this.contact_job_title = contact_job_title;
    this.contact_email = contact_email;
    this.contact_phone = contact_phone;
    this.bank_account_label = bank_account_label;
    this.type = type;
    this.company_size = company_size;
    this.is_efaktur_with_nik = is_efaktur_with_nik;
    this.nik_id = nik_id;
    this.nik_name = nik_name;
    this.nik_address = nik_address;
    this.taxpayer_name = taxpayer_name;
    this.taxpayer_address = taxpayer_address;
    this.billing_wa_number = billing_wa_number;
    this.billing_email = billing_email;
    this.billing_schedule_so = billing_schedule_so;
    this.billing_payment_periode = billing_payment_periode;
    this.va_bank_name = va_bank_name;
    this.va_bank_number = va_bank_number;
    this.va_bank_account_name = va_bank_account_name;
    this.va_bank_label = va_bank_label;
    this.va_bank_email = va_bank_email;
    this.contract_start_date = contract_start_date;
    this.contract_end_date = contract_end_date;
    this.contract_attachment = contract_attachment;
    this.client_type = client_type;
    this.payment_method = payment_method;
  }

  public toFormData() {
    const data = {
      company_name: this.company_name,
      email: this.email,
      phone: this.phone,
      address: this.address,
      district_code: this.district_code,
      tax_number: this.tax_number,
      version_basic: this.version_basic,
      is_custom_rate: this.is_custom_rate,
      is_custom_rate_only: this.is_custom_rate_only,
      discount: this.discount,
      price_rate_start_date: this.price_rate_start_date,
      price_rate_end_date: this.price_rate_end_date,
      version_name: this.version_name,
      archive_file: this.archive_file,
      category: this.category,
      bank_name: this.bank_name,
      bank_account_name: this.bank_account_name,
      bank_account_number: this.bank_account_number,
      bank_account_email: this.bank_account_email,
      bank_account_label: this.bank_account_label,
      contact_name: this.contact_name,
      contact_job_title: this.contact_job_title,
      contact_email: this.contact_email,
      contact_phone: this.contact_phone,
      type: this.type,
      company_size: this.company_size,
      is_efaktur_with_nik: this.is_efaktur_with_nik,
      nik_id: this.nik_id,
      nik_name: this.nik_name,
      nik_address: this.nik_address,
      taxpayer_name: this.taxpayer_name,
      taxpayer_address: this.taxpayer_address,
      billing_wa_number: this.billing_wa_number,
      billing_email: this.billing_email,
      billing_schedule_so: this.billing_schedule_so,
      billing_payment_period: this.billing_payment_periode,
      va_bank_name: this.va_bank_name,
      va_bank_number: this.va_bank_number,
      va_bank_account_name: this.va_bank_account_name,
      va_bank_label: this.va_bank_label,
      va_bank_email: this.va_bank_email,
      contract_start_date: this.contract_start_date,
      contract_end_date: this.contract_end_date,
      contract_attachment: this.contract_attachment,
      client_type: this.client_type,
      payment_method: this.payment_method
    };
    return toFormDataUtils(data);
  }
}

export class ClientRegistrationApproveApiRequest
  implements ClientRegistrationApproveRejectRequest {
  private id: number;

  constructor(id: number) {
    this.id = id;
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id
    });
  }
}

export class ClientRegistrationRejectApiRequest
  implements ClientRegistrationApproveRejectRequest {
  private id: number;
  private reject_reason: string;

  constructor(id: number, reject_reason: string) {
    this.id = id;
    this.reject_reason = reject_reason;
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      reject_reason: this.reject_reason
    });
  }
}

export class CreateClientBranchRegistration
  implements CreateClientBranchRequest {
  private client_branch_name: string;
  private client_branch_address: string;
  private client_branch_district_code: string;
  private client_branch_email: string;
  private client_branch_phone_number: string;
  private client_branch_tax_number: string;
  private client_branch_parent_id: number;
  private client_branch_partner_pos_id: number;
  private client_branch_partner_pos_commission: number;
  private client_branch_partner_pos_commission_type: string;
  private client_branch_contact_name: string;
  private client_branch_contact_job_title: string;
  private client_branch_contact_email: string;
  private client_branch_contact_phone: string;
  private client_branch_is_cod: boolean;
  private client_branch_is_do: boolean;
  private client_branch_return_cod_name: string;
  private client_branch_return_cod_phone: string;
  private client_branch_return_cod_address: string;
  private client_branch_return_cod_city_code: string;
  private client_branch_return_cod_district_code: string;
  private client_branch_return_cod_fee: number;
  private client_branch_partner_pos_commission_numerator: string;
  private client_branch_pad_fee: number;
  private client_cod_config_amount: string;
  private client_cod_shipment_discount: number;
  private client_branch_do_payment_type: string;
  private client_is_dfod: boolean;

  constructor(
    client_branch_name: string,
    client_branch_address: string,
    client_branch_district_code: string,
    client_branch_email: string,
    client_branch_phone_number: string,
    client_branch_tax_number: string,
    client_branch_parent_id: number,
    client_branch_partner_pos_id: number,
    client_branch_partner_pos_commission: number,
    client_branch_partner_pos_commission_type: string,
    client_branch_contact_name: string,
    client_branch_contact_job_title: string,
    client_branch_contact_email: string,
    client_branch_contact_phone: string,
    client_branch_is_cod: boolean,
    client_branch_is_do: boolean,
    client_branch_return_cod_name: string,
    client_branch_return_cod_phone: string,
    client_branch_return_cod_address: string,
    client_branch_return_cod_city_code: string,
    client_branch_return_cod_district_code: string,
    client_branch_return_cod_fee: number,
    client_branch_partner_pos_commission_numerator: string,
    client_branch_pad_fee: number,
    client_cod_config_amount: string,
    client_cod_shipment_discount: number,
    client_branch_do_payment_type: string,
    client_is_dfod: boolean
  ) {
    this.client_branch_name = client_branch_name;
    this.client_branch_address = client_branch_address;
    this.client_branch_district_code = client_branch_district_code;
    this.client_branch_email = client_branch_email;
    this.client_branch_phone_number = client_branch_phone_number;
    this.client_branch_tax_number = client_branch_tax_number;
    this.client_branch_parent_id = client_branch_parent_id;
    this.client_branch_partner_pos_id = client_branch_partner_pos_id;
    this.client_branch_partner_pos_commission = client_branch_partner_pos_commission;
    this.client_branch_partner_pos_commission_type = client_branch_partner_pos_commission_type;
    this.client_branch_contact_name = client_branch_contact_name;
    this.client_branch_contact_job_title = client_branch_contact_job_title;
    this.client_branch_contact_email = client_branch_contact_email;
    this.client_branch_contact_phone = client_branch_contact_phone;
    this.client_branch_is_cod = client_branch_is_cod;
    this.client_branch_is_do = client_branch_is_do;
    this.client_branch_return_cod_name = client_branch_return_cod_name;
    this.client_branch_return_cod_phone = client_branch_return_cod_phone;
    this.client_branch_return_cod_address = client_branch_return_cod_address;
    this.client_branch_return_cod_city_code = client_branch_return_cod_city_code;
    this.client_branch_return_cod_district_code = client_branch_return_cod_district_code;
    this.client_branch_return_cod_fee = client_branch_return_cod_fee;
    this.client_branch_partner_pos_commission_numerator = client_branch_partner_pos_commission_numerator;
    this.client_branch_pad_fee = client_branch_pad_fee;
    this.client_cod_config_amount = client_cod_config_amount;
    this.client_cod_shipment_discount = client_cod_shipment_discount;
    this.client_branch_do_payment_type = client_branch_do_payment_type;
    this.client_is_dfod = client_is_dfod;
  }

  public toFormData(): FormData {
    const data = {
      client_branch_name: this.client_branch_name,
      client_branch_address: this.client_branch_address,
      client_branch_district_code: this.client_branch_district_code,
      client_branch_email: this.client_branch_email,
      client_branch_phone_number: this.client_branch_phone_number,
      client_branch_tax_number: this.client_branch_tax_number,
      client_branch_parent_id: this.client_branch_parent_id,
      client_branch_partner_pos_id: this.client_branch_partner_pos_id,
      client_branch_partner_pos_commission: this
        .client_branch_partner_pos_commission,
      client_branch_partner_pos_commission_type: this
        .client_branch_partner_pos_commission_type,
      client_branch_contact_name: this.client_branch_contact_name,
      client_branch_contact_job_title: this.client_branch_contact_job_title,
      client_branch_contact_email: this.client_branch_contact_email,
      client_branch_contact_phone: this.client_branch_contact_phone,
      client_branch_is_cod: this.client_branch_is_cod,
      client_branch_is_do: this.client_branch_is_do,
      client_branch_cod_return_receiver_name: this
        .client_branch_return_cod_name,
      client_branch_cod_return_receiver_phone: this
        .client_branch_return_cod_phone,
      client_branch_cod_return_receiver_address: this
        .client_branch_return_cod_address,
      client_branch_cod_return_receiver_city_code: this
        .client_branch_return_cod_city_code,
      client_branch_cod_return_receiver_district_code: this
        .client_branch_return_cod_district_code,
      client_branch_cod_fee: this.client_branch_return_cod_fee,
      client_branch_partner_pos_commission_numerator: this
        .client_branch_partner_pos_commission_numerator,
      client_pad_fee: this.client_branch_pad_fee,
      client_cod_config_amount: this.client_cod_config_amount,
      client_cod_shipment_discount: this.client_cod_shipment_discount,
      client_branch_do_payment_type: this.client_branch_do_payment_type,
      client_branch_is_dfod: this.client_is_dfod
    };
    return toFormDataUtils(data);
  }
}

export class EditClientRegistrationApiRequest
  implements EditClientRegistrationApiRequest {
  id: number;
  company_name: string;
  email: string;
  phone: string;
  address: string;
  tax_number: string;
  version_basic: string;
  is_custom_rate: boolean;
  price_rate_start_date: string;
  price_rate_end_date: string;
  discount: number;
  district_code: string;
  version_name: string;
  archive_file: any;
  is_update_custom: boolean;
  client_type: string;
  category: string;
  bank_name: string;
  bank_account_name: string;
  bank_account_number: string;
  bank_account_email: string;
  bank_account_label: string;
  contact_name: string;
  contact_job_title: string;
  contact_email: string;
  contact_phone: string;
  pos_id: string;
  pos_commission: string;
  pos_quantifier: string;
  is_do: boolean;
  is_cod: boolean;
  client_branch_return_cod_name: string;
  client_branch_return_cod_phone: string;
  client_branch_return_cod_address: string;
  client_branch_return_cod_city_code: string;
  client_branch_return_cod_district_code: string;
  client_branch_return_cod_fee: number;
  is_custom_rate_only: boolean;

  constructor(
    id: number,
    company_name: string,
    email: string,
    phone: string,
    address: string,
    district_code: string,
    tax_number: string,
    version_basic: string,
    is_custom_rate: boolean,
    discount: number,
    price_rate_start_date: string,
    price_rate_end_date: string,
    version_name: string,
    archive_file: any,
    is_update_custom: boolean,
    client_type: string,
    category: string,
    bank_name: string,
    bank_account_name: string,
    bank_account_number: string,
    bank_account_email: string,
    bank_account_label: string,
    contact_name: string,
    contact_job_title: string,
    contact_email: string,
    contact_phone: string,
    pos_id: string,
    pos_commission: string,
    pos_quantifier: string,
    is_do: boolean,
    is_cod: boolean,
    client_branch_return_cod_name: string,
    client_branch_return_cod_phone: string,
    client_branch_return_cod_address: string,
    client_branch_return_cod_city_code: string,
    client_branch_return_cod_district_code: string,
    client_branch_return_cod_fee: number,
    is_custom_rate_only: any
  ) {
    this.id = id;
    this.company_name = company_name;
    this.email = email;
    this.phone = phone;
    this.address = address;
    this.district_code = district_code;
    this.tax_number = tax_number;
    this.version_basic = version_basic;
    this.is_custom_rate = is_custom_rate;
    this.discount = discount;
    this.price_rate_start_date = price_rate_start_date;
    this.price_rate_end_date = price_rate_end_date;
    this.version_name = version_name;
    this.archive_file = archive_file;
    this.is_update_custom = is_update_custom;
    this.client_type = client_type;
    this.category = category;
    this.bank_name = bank_name;
    this.bank_account_name = bank_account_name;
    this.bank_account_number = bank_account_number;
    this.bank_account_email = bank_account_email;
    this.bank_account_label = bank_account_label;
    this.contact_name = contact_name;
    this.contact_job_title = contact_job_title;
    this.contact_email = contact_email;
    this.contact_phone = contact_phone;
    this.pos_id = pos_id;
    this.pos_commission = pos_commission;
    this.pos_quantifier = pos_quantifier;
    this.is_do = is_do;
    this.is_cod = is_cod;
    (this.client_branch_return_cod_name = client_branch_return_cod_name),
      (this.client_branch_return_cod_phone = client_branch_return_cod_phone),
      (this.client_branch_return_cod_address = client_branch_return_cod_address),
      (this.client_branch_return_cod_city_code = client_branch_return_cod_city_code),
      (this.client_branch_return_cod_district_code = client_branch_return_cod_district_code),
      (this.client_branch_return_cod_fee = client_branch_return_cod_fee),
      (this.is_custom_rate_only = is_custom_rate_only);
  }

  public toFormData() {
    const data = {
      client_type: this.client_type,
      id: this.id,
      company_name: this.company_name,
      address: this.address,
      district_code: this.district_code,
      email: this.email,
      phone: this.phone,
      tax_number: this.tax_number,
      contact_name: this.contact_name,
      contact_job_title: this.contact_job_title,
      contact_email: this.contact_email,
      contact_phone: this.contact_phone,
      client_branch_cod_return_receiver_name: this
        .client_branch_return_cod_name,
      client_branch_cod_return_receiver_phone: this
        .client_branch_return_cod_phone,
      client_branch_cod_return_receiver_address: this
        .client_branch_return_cod_address,
      client_branch_cod_return_receiver_city_code: this
        .client_branch_return_cod_city_code,
      client_branch_cod_return_receiver_district_code: this
        .client_branch_return_cod_district_code,
      client_branch_cod_fee: this.client_branch_return_cod_fee,
      is_custom_rate_only: this.is_custom_rate_only
    };
    // parent
    if (this.pos_id) {
      return toFormDataUtils({
        ...data,
        pos_id: this.pos_id,
        pos_commission: this.pos_commission,
        pos_quantifier: this.pos_quantifier,
        is_do: this.is_do,
        is_cod: this.is_cod
      });
      // branch
    } else {
      return toFormDataUtils({
        ...data,
        category: this.category,
        bank_name: this.bank_name,
        bank_account_name: this.bank_account_name,
        bank_account_number: this.bank_account_number,
        bank_account_email: this.bank_account_email,
        bank_account_label: this.bank_account_label,
        version_basic: this.version_basic,
        discount: this.discount,
        is_custom_rate: this.is_custom_rate,
        price_rate_start_date: this.price_rate_start_date,
        price_rate_end_date: this.price_rate_end_date,
        version_name: this.version_name,
        archive_file: this.archive_file,
        is_update_custom: this.is_update_custom
      });
    }
  }
}

export class EditRenewRatesApiRequest implements EditRenewRatesRequest {
  private id: number;
  private versionBasic: string;
  private discount: string;
  private isCustomRate: boolean;
  private versionName: string;
  private archiveFile: any;
  private startDate: string;
  private endDate: string;
  private isCustomRateOnly: boolean;

  constructor(
    id: number,
    versionBasic: string,
    discount: string,
    isCustomRate: boolean,
    versionName: string,
    archiveFile: any,
    startDate: string,
    endDate: string,
    isCustomRateOnly: boolean
  ) {
    (this.id = id),
      (this.versionBasic = versionBasic),
      (this.discount = discount),
      (this.isCustomRate = isCustomRate),
      (this.versionName = versionName),
      (this.archiveFile = archiveFile),
      (this.startDate = startDate),
      (this.endDate = endDate),
      (this.isCustomRateOnly = isCustomRateOnly);
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      version_basic: this.versionBasic,
      discount: this.discount,
      is_custom_rate: this.isCustomRate,
      version_name: this.versionName,
      archive_file: this.archiveFile,
      start_date: this.startDate,
      end_date: this.endDate,
      is_custom_rate_only: this.isCustomRateOnly
    });
  }

  public toFormData() {
    const data = {
      id: this.id,
      version_basic: this.versionBasic,
      discount: this.discount,
      is_custom_rate: this.isCustomRate,
      version_name: this.versionName,
      archive_file: this.archiveFile,
      start_date: this.startDate,
      end_date: this.endDate,
      is_custom_rate_only: this.isCustomRateOnly
    };

    return toFormDataUtils({
      ...data
    });
  }
}
