/* eslint-disable @typescript-eslint/camelcase */
import {
  ClientRegistrationEntities,
  ClientRegistrationDetail,
  BankDetail
} from "@/domain/entities/ClientRegistration";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { ClientRegistrationPresenter } from "../presenters/ClientRegistrationPresenter";
import { container } from "tsyringe";
import {
  ClientRegistrationApiRequest,
  ClientRegistrationApproveApiRequest,
  ClientRegistrationRejectApiRequest,
  CreateClientBranchRegistration,
  EditClientRegistrationApiRequest,
  EditRenewRatesApiRequest
} from "@/data/payload/api/ClientRegistrationApiRequest";
import { EditClientManagementBasicInformationRequest } from "@/data/payload/api/ClientManagementApiRequest";
import { BaseRatesPresenter } from "@/app/ui/presenters/BaseRatesPresenter";
import { BaseRatesData, BaseRatesEntities } from "@/domain/entities/BaseRates";
import { LocationPresenter } from "@/app/ui/presenters/LocationPresenter";
import { DistrictEntities } from "@/domain/entities/Location";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import router from "@/app/ui/router";

export interface ClientRegistrationState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  clientRegistrationData: ClientRegistrationEntities;
  clientRegistrationDetail: ClientRegistrationDetail;
  isError: boolean;
  isErrorEdit: boolean;
  isErrorCause: string;
  openModal: boolean;
  openModalSuccess: boolean;
  messageSuccess: string;
  contractStartEdit: Date;
  contractEndEdit: Date;
}
@Module({ namespaced: true, dynamic: true, store, name: "client_registration" })
class ClientRegistrationStore extends VuexModule
  implements ClientRegistrationState {
  public isLoading = false;
  public isLoadingDetail = false;
  public messageSuccess = "";
  public contractStartEdit = new Date();
  public contractEndEdit = new Date();
  public clientRegistrationData = new ClientRegistrationEntities(
    new Pagination(1, 10),
    []
  );
  public cookiesClientRegistrationDetail = new ClientRegistrationDetail();
  public clientRegistrationDetail = new ClientRegistrationDetail();
  public search = "";
  public filterStatus = "";
  public filterType = "";
  public isError = false;
  public isErrorRenewRates = false;
  public isErrorEdit = false;
  public isErrorCause = "";
  public openModal = false;
  public openModalSuccess = false;
  public firstRequest = true;
  public openSuccess = false;
  public clientStatus = "";
  public versionRateList: Array<BaseRatesData> = [];
  public loadingVersionRateList = true;
  public districtList: Array<any> = [];
  public loadingDistrictList = true;
  public isAddBank = false;
  public isAddBankSettlement = false;
  public bankDetail = new BankDetail();
  public bankDetailSettlement = new BankDetail();
  public isSnackbarSuccess = "";
  public isSnackbarFailed = "";

  @Action
  public createClientBranch(params: {
    branchName: string;
    branchAddress: string;
    branchDistrictCode: string;
    branchEmail: string;
    branchPhoneNumber: string;
    branchTaxNumber: string;
    branchParentId: number;
    branchPartnerPosId: number;
    branchPartnerPosCommission: number;
    branchPartnerPosCommissionType: string;
    branchContactName: string;
    branchContactJobTitle: string;
    branchContactEmail: string;
    branchContactPhone: string;
    branchIsCod: boolean;
    branchIsDo: boolean;
    branchReturnCodName: string;
    branchReturnCodPhone: string;
    branchReturnCodAddress: string;
    branchReturnCodCity: string;
    branchReturnCodDistrict: string;
    branchReturnCodFee: number;
    clientBranchPartnerPosCommissionNumerator: string;
    branchPadFee: number;
    clientCodConfigAmount: string;
    clientCodShipmentDiscount: number;
    clientBranchDoPaymentType: string;
    clientIsDfod: boolean;
  }) {
    this.setOpenModal(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ClientRegistrationPresenter);
    return presenter
      .createClientBranchRegistration(
        new CreateClientBranchRegistration(
          params.branchName,
          params.branchAddress,
          params.branchDistrictCode,
          params.branchEmail,
          params.branchPhoneNumber,
          params.branchTaxNumber,
          params.branchParentId,
          params.branchPartnerPosId,
          params.branchPartnerPosCommission,
          params.branchPartnerPosCommissionType,
          params.branchContactName,
          params.branchContactJobTitle,
          params.branchContactEmail,
          params.branchContactPhone,
          params.branchIsCod,
          params.branchIsDo,
          params.branchReturnCodName,
          params.branchReturnCodPhone,
          params.branchReturnCodAddress,
          params.branchReturnCodCity,
          params.branchReturnCodDistrict,
          params.branchReturnCodFee,
          params.clientBranchPartnerPosCommissionNumerator,
          params.branchPadFee,
          params.clientCodConfigAmount,
          params.clientCodShipmentDiscount,
          params.clientBranchDoPaymentType,
          params.clientIsDfod
        )
      )
      .then(() => {
        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembuatan Gagal !", () =>
            this.createClientBranch(params)
          )
        );
        return false;
      })
      .finally(() => {
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public _onGetList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
    type: string;
  }) {
    this.setLoading(true);
    // set snackbar success approve on list
    if (this.isSnackbarSuccess) {
      MainAppController.setSnackbarMessage(
        `Pengajuan Client “${this.isSnackbarSuccess}” berhasil disetujui !`
      );
      MainAppController.setSnackbarTimeout(0);
      MainAppController.setSnackbar(true);
    }
    // set snackbar success rejected on list
    if (this.isSnackbarFailed) {
      MainAppController.setSnackbarMessage(
        `Pengajuan Client “${this.isSnackbarFailed}” berhasil ditolak !`
      );
      MainAppController.setSnackbarTimeout(0);
      MainAppController.setSnackbar(true);
      MainAppController.setSnackbarPositivity(false);
    }
    const presenter = container.resolve(ClientRegistrationPresenter);
    presenter
      .getListClientRegistration(
        params.page,
        params.limit,
        params.search,
        params.status,
        params.type
      )
      .then((res: ClientRegistrationEntities) => {
        this.setClientRegistrationData({
          pagination: {
            page: res.pagination.page,
            limit: params.limit
          },
          data: res.data
        });
        this.setOptionsClientData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async getDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(ClientRegistrationPresenter);
    return presenter
      .getDetailClientRegistration(id)
      .then((res: ClientRegistrationDetail) => {
        this.setClientRegistrationDetail(res);
        this.getVersionRateList({
          search: this.clientRegistrationDetail.clientCurrentRate
            .clientVersionBaseRate
        });
        this.setContractStartEdit(
          new Date(res.clientCurrentRate.clientRateVersionStartDate)
        );
        this.setContractEndEdit(
          new Date(res.clientCurrentRate.clientRateVersionEndate)
        );
        BeneficiaryAccountController.getBeneficiaryAccList({
          page: 1,
          limit: 1,
          bankAccType: "beneficiary",
          actortype: "client",
          actorId: id
        });
        this.setCookiesClientRegistrationDetail(res);
        this.setClientStatus(res.clientStatus.toLowerCase());
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public addClientRegistration(params: {
    companyName: string;
    email: string;
    phone: string;
    address: string;
    district: any;
    taxNumber: string;
    versionBasic: any;
    isCustomRate: boolean;
    isCustomRateOnly: boolean;
    discount: number;
    priceRateStartDate: string;
    priceRateEndDate: string;
    versionName: string;
    archiveFile: any;
    category: string;
    bankName: string;
    bankAccountName: string;
    bankAccountNumber: string;
    bankAccountEmail: string;
    bankAccountLabel: string;
    contactName: string;
    contactJobTitle: string;
    contactEmail: string;
    contactPhone: string;
    type: string;
    company_size: string;
    is_efaktur_with_nik: string;
    nik_id: string;
    nik_name: string;
    nik_address: string;
    taxpayer_name: string;
    taxpayer_address: string;
    billing_wa_number: string;
    billing_email: string;
    billing_schedule_so: string;
    billing_payment_periode: string;
    va_bank_name: string;
    va_bank_number: string;
    va_bank_account_name: string;
    va_bank_label: string;
    va_bank_email: string;
    contract_start_date: string;
    contract_end_date: string;
    contract_attachment: any;
    client_type: string;
    payment_method: string;
  }) {
    this.setOpenModal(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ClientRegistrationPresenter);
    return presenter
      .addClientRegistration(
        new ClientRegistrationApiRequest(
          params.companyName,
          params.email,
          params.phone,
          params.address,
          params.district?.code,
          params.taxNumber,
          params.versionBasic.rateVersionName,
          params.isCustomRate,
          params.isCustomRateOnly,
          params.discount,
          params.priceRateStartDate,
          params.priceRateEndDate,
          params.versionName,
          params.archiveFile,
          params.category,
          params.bankName,
          params.bankAccountName,
          params.bankAccountNumber,
          params.bankAccountEmail,
          params.bankAccountLabel,
          params.contactName,
          params.contactJobTitle,
          params.contactEmail,
          params.contactPhone,
          params.type,
          params.company_size,
          params.is_efaktur_with_nik,
          params.nik_id,
          params.nik_name,
          params.nik_address,
          params.taxpayer_name,
          params.taxpayer_address,
          params.billing_wa_number,
          params.billing_email,
          params.billing_schedule_so,
          params.billing_payment_periode,
          params.va_bank_name,
          params.va_bank_number,
          params.va_bank_account_name,
          params.va_bank_label,
          params.va_bank_email,
          params.contract_start_date,
          params.contract_end_date,
          params.contract_attachment,
          params.client_type,
          params.payment_method
        )
      )
      .then(() => {
        return true;
      })
      .catch(err => {
        if (
          err.response &&
          err.response.data.message.id.toLowerCase() ===
            "berkas custom rate salah atau tidak sesuai"
        ) {
          this.setErrorDocument(true);
        } else if (
          err.response &&
          err.response.data.message.id.toLowerCase().includes("telah digunakan")
        ) {
          this.setIsExist(true);
        } else {
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "Pembuatan Gagal !", () =>
              this.addClientRegistration(params)
            )
          );
        }
        return false;
      })
      .finally(() => {
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public editClientRegistration(params: {
    isOnlyEditInfo: boolean;
    id: number;
    companyName: string;
    email: string;
    phone: string;
    address: string;
    district: string;
    taxNumber: string;
    versionBasic: any;
    isCustomRate: boolean;
    discount: number;
    priceRateStartDate: string;
    priceRateEndDate: string;
    versionName: string;
    archiveFile: any;
    isUpdateCustom: boolean;
    clientType: string;
    category: string;
    bankName: any;
    bankAccountName: any;
    bankAccountNumber: any;
    bankAccountEmail: any;
    bankAccountLabel: any;
    contactName: string;
    contactJobTitle: string;
    contactEmail: string;
    contactPhone: string;
    posId: any;
    posCommission: any;
    posQuantifier: string;
    isDo: boolean;
    isCod: boolean;
    branchReturnCodName: string;
    branchReturnCodPhone: string;
    branchReturnCodAddress: string;
    branchReturnCodCity: any;
    branchReturnCodDistrict: string;
    branchReturnCodFee: number;
    isCustomRateOnly?: any;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ClientRegistrationPresenter);
    return presenter
      .editClientRegistration(
        new EditClientRegistrationApiRequest(
          params.id,
          params.companyName,
          params.email,
          params.phone,
          params.address,
          params.district,
          params.taxNumber,
          params.versionBasic,
          params.isCustomRate,
          params.discount,
          params.priceRateStartDate,
          params.priceRateEndDate,
          params.versionName,
          params.archiveFile,
          params.isUpdateCustom,
          params.clientType,
          params.category,
          params.bankName,
          params.bankAccountName,
          params.bankAccountNumber,
          params.bankAccountEmail,
          params.bankAccountLabel,
          params.contactName,
          params.contactJobTitle,
          params.contactEmail,
          params.contactPhone,
          params.posId,
          params.posCommission,
          params.posQuantifier,
          params.isDo,
          params.isCod,
          params.branchReturnCodName,
          params.branchReturnCodPhone,
          params.branchReturnCodAddress,
          params.branchReturnCodCity,
          params.branchReturnCodDistrict,
          params.branchReturnCodFee,
          params.isCustomRateOnly
        )
      )
      .then(() => {
        if (params.isOnlyEditInfo) {
          this.setMessageSuccess(
            `Detail Client "${params.companyName}" berhasil diubah.`
          );
          this.setOpenModalSuccess(true);
        }

        return true;
      })
      .catch((err: any) => {
        if (
          err.response &&
          err.response.data.message.id &&
          err.response.data.message.id.toLowerCase() ===
            "berkas custom rate salah atau tidak sesuai"
        ) {
          this.setErrorDocument(true);
        } else {
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "Perubahan Gagal !", () =>
              this.editClientRegistration(params)
            )
          );
          this.setOpenModalSuccess(false);
        }

        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async editRenewRates(params: {
    id: number;
    versionBasic: string;
    discount: string;
    isCustomRate: boolean;
    versionName: string;
    archiveFile: any;
    startDate: string;
    endDate: string;
    isCustomRateOnly: boolean;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ClientRegistrationPresenter);
    return presenter
      .editRenewRates(
        new EditRenewRatesApiRequest(
          params.id,
          params.versionBasic,
          params.discount,
          params.isCustomRate,
          params.versionName,
          params.archiveFile,
          params.startDate,
          params.endDate,
          params.isCustomRateOnly
        )
      )
      .then(() => {
        this.setMessageSuccess(`Detail Client berhasil diubah.`);
        this.setOpenModalSuccess(true);
        return true;
      })
      .catch((err: any) => {
        if (
          err.response &&
          err.response.data.message.id &&
          err.response.data.message.id.toLowerCase() ===
            "berkas custom rate salah atau tidak sesuai"
        ) {
          this.setErrorDocument(true);
        } else {
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "Perubahan Gagal !", () =>
              this.editRenewRates(params)
            )
          );
          this.setOpenModalSuccess(false);
        }
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async editClientManagementBasicInformation(
    params: EditClientManagementBasicInformationRequest
  ) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ClientRegistrationPresenter);
    return presenter
      .editClientManagemetBasicInformation(params)
      .then(() => {
        MainAppController.setSnackbarMessage(
          "Perubahan detail client berhasil"
        );
        MainAppController.setSnackbarTimeout(2000);
        MainAppController.setSnackbar(true);
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Gagal !", () =>
            this.editClientManagementBasicInformation(params)
          )
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async approveClientRegistration(params: { id: number }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ClientRegistrationPresenter);
    await presenter
      .approveClientRegistration(
        new ClientRegistrationApproveApiRequest(params.id)
      )
      .then(() => {
        router.push("/client/client-registration");
        this.setSnackbarSuccess(this.clientRegistrationDetail.clientName);
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Persetujuan Gagal !", () =>
            this.approveClientRegistration(params)
          )
        );
        this.setOpenModalSuccess(false);
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async rejectClientRegistration(params: {
    id: number;
    rejectReason: string;
  }) {
    this.setOpenModal(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ClientRegistrationPresenter);
    await presenter
      .rejectClientRegistration(
        new ClientRegistrationRejectApiRequest(params.id, params.rejectReason)
      )
      .then(() => {
        router.push("/client/client-registration");
        this.setSnackbarFailed(this.clientRegistrationDetail.clientName);
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Penolakan Gagal !", () =>
            this.rejectClientRegistration(params)
          )
        );
        this.setOpenModalSuccess(false);
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public fetchClientList() {
    this.setFirstRequest(false);
    this._onGetList({
      search: this.search,
      status: this.filterStatus,
      type: this.filterType,
      page: this.clientRegistrationData.pagination.page,
      limit: this.clientRegistrationData.pagination.limit
    });
  }

  @Action
  public initClient() {
    this.setFirstPage();
    this.setFirstRequest(true);
    this.setFilterStatus("");
    this.setFilterType("");
    this.setSearch("");
    this._onGetList({
      search: "",
      status: "",
      type: "",
      page: this.clientRegistrationData.pagination.page,
      limit: this.clientRegistrationData.pagination.limit
    });
  }

  @Action
  public setFirstPage() {
    this.clientRegistrationData.pagination.page = 1;
  }

  @Action
  public selectStatusAction(params: { name: string; value: string }) {
    this.setFilterStatus(params.value);
    this.setFirstPage();
    this.fetchClientList();
  }

  @Action
  public selectTypeAction(params: { name: string; value: string }) {
    this.setFilterType(params.value);
    this.setFirstPage();
    this.fetchClientList();
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    this.fetchClientList();
  }

  @Action
  public clear() {
    this.searchAction("");
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setClientRegistrationData(data: ClientRegistrationEntities) {
    this.clientRegistrationData = data;
  }

  @Mutation
  private setClientRegistrationDetail(data: ClientRegistrationDetail) {
    this.clientRegistrationDetail = data;
  }

  @Mutation
  private setCookiesClientRegistrationDetail(data: ClientRegistrationDetail) {
    this.cookiesClientRegistrationDetail = data;
  }

  @Mutation
  public setClientRegistrationDetailFromCookies() {
    const data = this.cookiesClientRegistrationDetail;
    this.clientRegistrationDetail = data;
  }

  resetSelectSearch = false;

  @Mutation
  public setResetClientRegistrationDetail() {
    this.clientRegistrationDetail = new ClientRegistrationDetail();
    this.resetSelectSearch = !this.resetSelectSearch;
  }

  @Mutation
  setClientStatus(value: string) {
    this.clientStatus = value;
  }

  @Mutation
  private setMessageSuccess(val: string) {
    this.messageSuccess = val;
  }

  @Mutation
  public setContractStartEdit(val: Date) {
    this.contractStartEdit = val;
  }

  @Mutation
  public setContractEndEdit(val: Date) {
    this.contractEndEdit = val;
  }

  @Mutation
  private setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  private setFilterStatus(value: string) {
    this.filterStatus = value;
  }

  @Mutation
  private setFilterType(value: string) {
    this.filterType = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  private setErrorRenewRates(boolean: boolean) {
    this.isErrorRenewRates = boolean;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Action
  public handleError() {
    this.setErrorRenewRates(false);
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Action
  public getVersionRateList(params: { search: string }) {
    this.setLoadingVersionRatesList(true);
    const presenter = container.resolve(BaseRatesPresenter);
    presenter
      .getRatesList(params.search)
      .then((res: BaseRatesEntities) => {
        this.setVersionRatesList(res.data);
      })
      .finally(() => {
        this.setLoadingVersionRatesList(false);
      });
  }

  @Mutation
  public setVersionRatesList(value: BaseRatesData[]) {
    this.versionRateList = value;
  }

  @Mutation
  public setLoadingVersionRatesList(value: boolean) {
    this.loadingVersionRateList = value;
  }

  // error
  public errorEditBasicInformation = false;
  public succesEditBasicInformation = false;
  @Mutation
  public setErrorEditBasicInformation(val: boolean) {
    this.errorEditBasicInformation = val;
  }
  @Mutation
  public setSuccesEditBasicInformation(val: boolean) {
    this.succesEditBasicInformation = val;
  }
  // Error Document
  isErrorDocument = false;
  @Mutation
  public setErrorDocument(val: boolean) {
    this.isErrorDocument = val;
  }

  // Is Exist
  isExist = false;
  @Mutation
  public setIsExist(val: boolean) {
    this.isExist = val;
  }

  // Date Picker
  public convertPeriodeStart = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1
  );
  public convertPeriodeEnd = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 2
  );

  @Mutation
  public setPeriodeStart(date: Date) {
    this.convertPeriodeStart = new Date(date);
  }
  @Mutation
  public setPeriodeEnd(date: Date) {
    this.convertPeriodeEnd = new Date(date);
  }

  @Mutation
  public setResetPeriodeStart() {
    this.convertPeriodeStart = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      new Date().getHours(),
      new Date().getMinutes() + 10
    );
  }
  @Mutation
  public setResetPeriodeEnd() {
    this.convertPeriodeEnd = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    );
  }

  public clientOptionsData: Array<any> = [];
  @Mutation
  public setOptionsClientData(data: ClientRegistrationEntities) {
    this.clientOptionsData = data.data;
  }

  @Action
  public getDistrictList(params: { search: string }) {
    this.setLoadingDistrictList(true);
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getDistrictList(params.search, "active", 1, 7, "", "", true)
      .then((res: DistrictEntities) => {
        this.setDistrictList(res.districtData);
      })
      .finally(() => {
        this.setLoadingDistrictList(false);
      });
  }

  @Mutation
  public setDistrictList(value: Array<any>) {
    this.districtList = value;
  }

  @Mutation
  public setLoadingDistrictList(value: boolean) {
    this.loadingDistrictList = value;
  }

  @Mutation
  public setDetailClientCategory(value: string) {
    this.clientRegistrationDetail.clientCategory = value;
  }

  @Mutation
  public setPartnerPos(value: any) {
    if (value === "") {
      this.clientRegistrationDetail.clientPartner.clientPartnerId = 0;
      this.clientRegistrationDetail.clientPartner.clientPartnerName = "";
    } else {
      this.clientRegistrationDetail.clientPartner.clientPartnerId = value.id;
      this.clientRegistrationDetail.clientPartner.clientPartnerName =
        value.name;
    }
  }

  @Mutation
  public setCommissionType(value: "") {
    this.clientRegistrationDetail.clientPartner.clientPartnerQuantifierCommission = value;
  }

  @Mutation
  public setAddBank(value: boolean) {
    this.isAddBank = value;
  }

  @Mutation
  public setBankDetail(data: BankDetail) {
    this.bankDetail = data;
  }

  @Mutation
  public setBankDetailSettlement(data: BankDetail) {
    this.bankDetailSettlement = data;
  }

  @Mutation
  public setAddBankSettlement(data: boolean) {
    this.isAddBankSettlement = data;
  }

  @Mutation
  public setSnackbarSuccess(val: string) {
    this.isSnackbarSuccess = val;
  }

  @Mutation
  public setSnackbarFailed(val: string) {
    this.isSnackbarFailed = val;
  }
}

export const ClientRegistrationController = getModule(ClientRegistrationStore);
